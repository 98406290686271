.menu2 {

    box-shadow: 0px 7px 6px 0px rgba(0,0,0,0.21);
-webkit-box-shadow: 0px 7px 6px 0px rgba(0,0,0,0.21);
-moz-box-shadow: 0px 7px 6px 0px rgba(0,0,0,0.21);

}
.fgdg{
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
}
.btn-skip2 {
    display: inline-block;
    text-align: center;
    background-color: #903;
    width: 100%;
    color: #ffffff;
    border: none;
    font-weight: 700;
    padding: 8px 25px;
    border-radius: 5px;

}

.cpy-btn {
    border: none;
    background-color: #2ba6cb;
    color: #ffffff;
    padding: 8px 10px;
    border-radius: 4px;
    padding-top: 12px;
    padding-bottom: 12px;
}